import React from "react"

export default () => (
  <div className="twitter">
    <a
      href="https://twitter.com/share?ref_src=twsrc%5Etfw"
      className="twitter-share-button"
      data-show-count="false"
      data-lang="ja"
    >
      ツイート
    </a>
    {
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      ></script>
    }
  </div>
)

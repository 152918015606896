import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import articleStyle from "../components/article.module.css"
import SnsShare from "../components/snsShare"
import Tags from "../components/tags"
import MaterialIcon from "../components/materialIcon"
import { Location } from "@reach/router"
import classNames from "classnames"

export default function BlogPost({ data, location }) {
  const post = data.markdownRemark
  return (
    <Layout path={location.pathname} >
      <Location>
        {({ location }) => (
          <SEO title={post.frontmatter.title} og_url={location.href} />
        )}
      </Location>
      <article className={articleStyle.article}>
        <div className={articleStyle.centering}>
          <header className={articleStyle.article_header}>
            <div className={articleStyle.article_header_summary}>
                <div className={articleStyle.article_header_info}>
                  <Tags tags={post.frontmatter.tags} />
                  <div className={articleStyle.article_header_time}>
                    <MaterialIcon iconName="calendar_today" text="" />
                    <time>{post.frontmatter.date}</time>
                  </div>
                </div>
                <h2 className={articleStyle.article_header_title}>
                  {post.frontmatter.title}
                </h2>
            </div>
          </header>
          <div className={articleStyle.article_wrap}>
            <div className={classNames(articleStyle.article_inner,articleStyle.centering)}>
              <div className={articleStyle.article_content} dangerouslySetInnerHTML={{ __html: post.html }} />
              <div className={articleStyle.article_sns}>
              <SnsShare />
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
      }
    }
  }
`

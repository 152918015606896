import React from "react"
import TagStyles from "../components/tagStyles.module.css"
import ClassNames from "classnames"

export default ({ tags }) => {
  const tagClass = tag => {
    switch (tag) {
      case "サイボウズ Office":
        return "tag_office"
      case "Garoon":
        return "tag_garoon"
      case "kintone":
        return "tag_kintone"
      default:
        return ""
    }
  }
  return (
    <div className={ClassNames(TagStyles.tag_list)}>
      {tags.map((tag, index) => (
        <div
          className={ClassNames(
            TagStyles.tag_list_item,
            TagStyles[tagClass(tag)]
          )}
          key={index}
        >
          {tag}
        </div>
      ))}
    </div>
  )
}

import React from "react"
import articleStyle from "../components/article.module.css"
import TwitterShare from "../components/twitterShare"
// import FacebookShare from "../components/facebookShare"

export default () => (
  <div className={articleStyle.snsButtons}>
    <TwitterShare />
    {/* <FacebookShare className={articleStyle.facebook} /> */}
  </div>
)
